<template>
	<div>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->

		<v-container>
			<v-tabs v-model="currentItem">
				<v-tab href="#Suplying"> Abastecer</v-tab>
				<v-tab href="#SuplyingEarring"> Pendientes por Abastecer</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentItem">
				<v-tab-item :value="'Suplying'"> 
                   <supplying />
                </v-tab-item>

				<v-tab-item :value="'SuplyingEarring'"> 
                    <supplying-earring />
                </v-tab-item>
			</v-tabs-items>
		</v-container>
	</div>
</template>
<script>
	//Component
	import Supplying from "./Supplying.vue";
    import SupplyingEarring from "./SupplyingEarring.vue";

	export default {
		components: { Supplying, SupplyingEarring},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
