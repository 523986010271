import Service from "../Service";

const resource = "SupplyingDetail/"

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    getSupplyingByLote(requestID, LlpID, TypeDestiny) {
        if (TypeDestiny > 0 && LlpID > 0)
            return Service.post(resource + "getSupplyingByLote", "", {
                params: { requestID: requestID, LlpID: LlpID, TypeDestiny: TypeDestiny },
            });
    },

};