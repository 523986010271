import Service from "../Service";

const resource = "Supplying/"

export default {

    getLoteReception(requestID) {
        return Service.post(resource + "getLoteReception", "", {
            params: { requestID: requestID },
        });
    },

    getSupplyingEarring(obj, requestID) {
        return Service.post(resource + "getSupplyingEarring", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {

        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

};