<template v-slot:no-data>
	<div>
		<v-container>
			<v-card style="margin-top: 15px; margin-bottom: 10px;">
				<v-card-text
					style="height: 40px; -webkit-box-shadow: 2px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%); border-radius: 5px; col-md-6;"
				>
					<b>Abastecimiento por QR</b>
				</v-card-text>

				<v-row style="margin: auto">
					<v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							@onData="onData($event)"
							:readonly="false"
						></s-scanner-qr>
					</v-col>
				</v-row>
			</v-card>

			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense flat>
						<v-row justify="center" style="margin: auto">

							<v-col cols="12" lg="12" md="12">
								<s-select-definition
									label="Destino"
									:def="1181"
									v-model="TypeDestinyTk"
								>
								</s-select-definition>
							</v-col>
							<v-col cols="12" lg="12" md="12">
								<s-select-definition
									label="Linea Empaque"
									:def="1315"
									v-model="TypeLineProcess"
								>
								</s-select-definition>
							</v-col>
							<!-- <v-col cols="6" lg="4" md="4">
								<s-select-definition
									label="Destino"
									:def="1181"
									v-model="TypeDestiny"
									readonly
								>
								</s-select-definition>
							</v-col> -->
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-data-table
									item-key="LppID"
									dense
									v-model="selected"
									:items-per-page="-1"
									:items="itemsSupplyingDetail"
									:headers="headerSupplyingDetail"
									hide-default-footer
									disable-sort
									:mobile-breakpoint="'1024'"
								>
									<!-- <template v-slot:item.Actions="{ item }">
										<v-row>
											<v-col>
												<v-btn
													@click="saveSupplying(item)"
													x-small
													:color="item.SpdID > 0 ? 'warning' : 'success'"
													:height="30"
													dark
													rounded
													shaped
													style="border-radius: 13px; margin: 10px 10px 10px 10px;"
												>{{
														item.SpdID > 0
															? "Terminar Abastecimiento"
															: "Abastecer Empaque: "
													}}
												</v-btn>
											</v-col>
										</v-row>
									</template> -->
									<template v-slot:item.RcfID="{ item }">
										<v-chip
											small
											:color="'info'"
										>
											{{item.RcfID}}
										</v-chip>
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import _sSupplying from "../../../services/FreshProduction/SupplyingService";
	import _sSupplyingDetail from "../../../services/FreshProduction/SupplyingDetailService";


	export default {
		components: {},
		data: () => ({
			selected: [],
			itemsSupplyingDetail: [],
			headerSupplyingDetail: [
				/* { text: "Acciones", value: "Actions", width: 100 }, */
				/* { text: "ID", value: "LppID", width: 50 }, */
				{ text: "N° Lote Prod.", value: "RcfIDName", width: 200 },
				{ text: "Tipo calibre", value: "TypeCaliberName", width: 100 },
				{ text: "Tipo cultivo", value: "TypeCropName", width: 200 },
				{ text: "Cultivo", value: "TypeCultiveName", width: 200 },
				{ text: "Cant. Jabas", value: "SpdQuantityJaba", width: 100 },
				{ text: "Variedad", value: "VrtDescription" },
			],

			configScann: {},
			responseQR: [],
			TypeDestiny: 0,
			TypeDestinyTk: 0,
			TypeLineProcess: 0,
		}),

		watch: {
			itemsSupplyingDetail(){
				console.log("watch", this.itemsSupplyingDetail[0]);
				if(this.itemsSupplyingDetail[0] == undefined){

					return;
				}

				if (this.itemsSupplyingDetail.length == 0 && this.TypeDestiny == 2 ) {
					this.$fun.alert(
						"No se encontraron registros, Asegurese que Parihuela haya pasado por hidrotermico y/o el proceso haya terminado.",
						"warning",
					);
						
					this.responseQR = [];
				}

				if(this.TypeDestiny != this.TypeDestinyTk){
					this.$fun.alert("El Pallet va a un destino diferente al seleccionado.","warning");
					return;
				}

				if(this.itemsSupplyingDetail.length > 0){
					console.log("watch save", this.itemsSupplyingDetail[0]);
					 
					this.saveSupplying(this.itemsSupplyingDetail[0])
					this.itemsSupplyingDetail = [];
				} 
			}
		},
		methods: {
			onValue(val) {
				/*this.itemsSupplyingDetail = [];
				console.log('traemos los datos del codigo escaneado con onValue', val);
				if (val == "") val = 0;
				if (val > 0) {
					this.getSupplyingByLot(val);
					if (this.itemsSupplyingDetail.length == 0) {
						this.responseQR = [];
					}

					
				}*/
				console.log("onvalues", val)
			},

			onData(val) {
				console.log('traemos los datos del codigo escaneado con onData', val);
				if (val === undefined) {
					console.log("ingreso manual");
				} else {
					var res = val.split(",", 100);
					this.responseQR = res;
					this.TypeDestiny = parseInt(this.responseQR[6]);

					//comente este codigo que esta repitiendo la insercion a la bd
					this.getSupplyingByLot(parseInt(this.responseQR[0])); 
					console.log("this.itemsSupplyingDetail.length", this.itemsSupplyingDetail.length);
					
				}
				console.log("ondata")

			},

			getSupplyingByLot(LlpID = 0) {
				
				if(LlpID > 0 && this.TypeDestiny > 0){
					
					_sSupplyingDetail.getSupplyingByLote(this.$fun.getUserID(), LlpID, this.TypeDestiny).then((r) => {
						if(r.status == 200){
							this.itemsSupplyingDetail = r.data;
						} 
						
					});
				}
				
			},

			saveSupplying(item) {

				console.log(this.itemsSupplyingDetail);
				
				var message = "";
				item.SpdID = 0
				item.SppID = 0
				item.TypeLineProcess = this.TypeLineProcess
				//item.TypeDestiny = this.TypeDestiny
				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();


				_sSupplying
							.save(item, this.$fun.getUserID())
							.then(r => {
								if (r.status == 200) {
									this.$fun.alert(
										"Registrado correctamente",
										"success",
									);
									// this.getSupplyingByLot(item.LlpID)    //EN OBSERVACION
								} 

								this.itemsSupplyingDetail = [];
								



				//if (item.SpdID == 0) {
					
					/* let tamanio = this.itemsSupplyingDetail.length
					if(tamanio > 1){
						var obj = this.itemsSupplyingDetail.find((x) => x.SpdID > 0)

						if(obj !== undefined){
							if(obj.SpdID > 0){
								this.$fun.alert("Error, para continuar con el siguiente LP, debe terminar abastecimiento anterior.", "warning")
								return
							}
						}
						
					}
 */
					//item.SpdStatus = 1
					//item.SpdHourInitial = this.$fun.getHour();
					//item.SppID = null
/* 
					message = "Esta seguro de Abastecer al Lote Nro: ? " + item.RcfIDName;

					console.log("Guardar", item); */

				//} else{
					//item.SpdStatus = 2
					// item.LppState = 3;
					//item.SppID = null
					//item.SpdHourFinal = this.$fun.getHour();

				//	message = "Esta seguro de terminar abastecimiento al Lote Nro: ? " + item.RcfID;
				//} */

				/* this.$fun.alert(message, "question").then(val => {
					if (val.value) { */
						
						/* 	});
					} */

				})
				.catch(err => {
					this.itemsSupplyingDetail = [];
				});
			},

		},
	};
</script>
