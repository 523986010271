<template>
	<div>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->

		<v-container>
			<v-card flat>
				<v-row justify="center">
					<div class="col-lg-3 col-md-4 col-sm-4">
						<b>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</b>
					</div>

					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Inicio"
							v-model="BeginDate"
						></s-date>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Fin"
							v-model="EndDate"
						></s-date>
					</v-col>

                    <v-col cols="6" lg="3" md="3">
                        <s-select-definition :def="1181" label="Destino" v-model="TypeDestiny"></s-select-definition>
                    </v-col>
				</v-row>
			</v-card>
			<v-card dense style="margin-top: 20px">
				<v-row>
					<v-col cols="12">
						<v-data-table
							item-key="LytID"
							dense
							:headers="headers"
							:items="items"
							:search="search"
							disable-sort

						>

							<template v-slot:item.RcfID="row">
								<v-chip small dark outlined :color="'blue'">
									{{ row.item.RcfID }}
								</v-chip>
							</template>

							<template v-slot:item.InProcess="row">
								<v-chip small dark outlined :color="row.item.xCount == 0 ? 'error': 'success'">
									{{ row.item.InProcess }}
								</v-chip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import _sSupplying from "../../../services/FreshProduction/SupplyingService";
	export default {
		components: {},
		data() {
			return {
				headers: [
					{ text: "Parihuela", value: "LlpID", width: 100 },
					{ text: "Lote Producción", value: "RcfID", width: 200 },
                    { text: "Calibre", value: "TypeCaliberName", width: 100 },
                    { text: "Cant. Jabas", value: "LlpQuantityJaba", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Estado", value: "InProcess", width: 200 },
				],
				items: [],
				BeginDate: null,
				EndDate: null,
                TypeDestiny: 0,
				search: "",
			};
		},

		created() {
			//this.initialize()
		},

        watch : {
            'TypeDestiny'(){
                this.initialize()
            },
            'BeginDate'(){
                this.initialize()
            },
            'EndDate'(){
                this.initialize()
            }
        },

		methods: {
			initialize() {
				_sSupplying
					.getSupplyingEarring(
						{ BeginDate: this.BeginDate, EndDate: this.EndDate, TypeDestiny: this.TypeDestiny  },
						this.$fun.getUserID(),
					)
					.then(data => {
						if (data.status == 200) {
							this.items = data.data;

							this.items.forEach(element => {
								element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
								
							});
						}
					});
			},
		},
	};
</script>
